import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import Header from "./subCategory/Header";
import SubCategorySection from "./subCategory/SubCategorySection";
import SubcatProductSection from "./subCategory/SubcatProductSection";
import BeatLoader from "react-spinners/BeatLoader";

const Category = () => {
  const { shopbyCategory } = useSelector((state) => state.Category);
  const { CartItems, ProductTotal, ProductLoading } = useSelector(
    (state) => state.Cart
  );
  const [currentCatProduct, setCurrentCatProduct] = useState([]);
  const [showProduct, setShowProduct] = useState([]);
  const [subCatId, setSubCatId] = useState("");
  const [catId, setCatId] = useState("");
  const [temploading, setTempLoading] = useState(true);

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const caterl = params.caturl;
    let currCatId = shopbyCategory.find((cat) => cat.slugUrl === caterl)._id;
    setCatId(currCatId);
    if (!ProductLoading) {
      let catpro = ProductTotal.filter(
        (product) => product.CatId === currCatId
      );

      let filteroutofstock = catpro.filter((data) => data.OutOfStack === false);

      let fetchQntyproducts = filteroutofstock.map((product) => {
        let cartPackquentity = [];
        let packqnty = {};

        for (let index = 0; index < product.PackSizes.length; index++) {
          const element = product.PackSizes[index];
          packqnty = {
            packId: element._id,
            CartQuantity: 0,
            Pri: element.Pri,
          };
          cartPackquentity = [...cartPackquentity, packqnty];
          cartPackquentity = [...cartPackquentity];
        }
        product = { ...product, cartPackquentity };
        return product;
      });

      CartItems.forEach((cartitem) => {
        let itemIndex = -1;
        itemIndex = fetchQntyproducts.findIndex(
          (product) => String(product._id) === String(cartitem.ProductId)
        );

        if (itemIndex >= 0) {
          let packIndex = -1;

          packIndex = fetchQntyproducts[itemIndex].cartPackquentity.findIndex(
            (pack) => String(pack.packId) === String(cartitem.packsizeId)
          );

          fetchQntyproducts[itemIndex].cartPackquentity[
            packIndex
          ].CartQuantity = 1;
          if (packIndex >= 0) {
            fetchQntyproducts[itemIndex].cartPackquentity[
              packIndex
            ].CartQuantity = 1;
          }
        }
      });
      setShowProduct(fetchQntyproducts);
      setCurrentCatProduct(fetchQntyproducts);
    } else {
      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/grocery/grocerybycatid/${currCatId}`;
        const fetchproducts = await axios.get(url);
        try {
          if (fetchproducts.data.success === true) {
            setTempLoading(false);
            const prod = fetchproducts.data.grocery.filter(
              (data) => data.OutOfStack === false
            );

            let fetchQntyproducts = prod.map((product) => {
              let cartPackquentity = [];
              let packqnty = {};

              for (let index = 0; index < product.PackSizes.length; index++) {
                const element = product.PackSizes[index];
                packqnty = {
                  packId: element._id,
                  CartQuantity: 0,
                  Pri: element.Pri,
                };
                cartPackquentity = [...cartPackquentity, packqnty];
                cartPackquentity = [...cartPackquentity];
              }
              product = { ...product, cartPackquentity };
              return product;
            });

            CartItems.forEach((cartitem) => {
              let itemIndex = -1;
              itemIndex = fetchQntyproducts.findIndex(
                (product) => String(product._id) === String(cartitem.ProductId)
              );

              if (itemIndex >= 0) {
                let packIndex = -1;

                packIndex = fetchQntyproducts[
                  itemIndex
                ].cartPackquentity.findIndex(
                  (pack) => String(pack.packId) === String(cartitem.packsizeId)
                );

                fetchQntyproducts[itemIndex].cartPackquentity[
                  packIndex
                ].CartQuantity = 1;
                if (packIndex >= 0) {
                  fetchQntyproducts[itemIndex].cartPackquentity[
                    packIndex
                  ].CartQuantity = 1;
                }
              }
            });
            setShowProduct(fetchQntyproducts);
            setCurrentCatProduct(fetchQntyproducts);
          }
        } catch (error) {}
      };
      fetchData();
    }
  }, [params.caturl, ProductLoading]);

  const subCatChange = (currentsubCatId) => {
    if (currentsubCatId !== "") {
      setSubCatId(currentsubCatId);
      const currentSubCatproducts = currentCatProduct.filter(
        (subC) => subC.SubCatId === currentsubCatId
      );
      setShowProduct(currentSubCatproducts);
    } else {
      const currentSubCatproducts = currentCatProduct;
      setShowProduct(currentSubCatproducts);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Banner /> */}

      <section className="section-b-space shop-section">
        <div className="container">
          <div className="row">
            <div className="col-custome-3" style={{ overflow: "scroll", height: "85vh" }}>
            <style>
                {`.col-custome-3::-webkit-scrollbar{
                  display:none;
                }

                `}
              </style>
              <SubCategorySection onFocus={subCatChange} categoryId={catId} />
            </div>
            <div className="col-custome-9" style={{overflow:"scroll", height:"85vh"}}>
              {ProductLoading ? (
                <>
                  {temploading ? (
                    <>
                      <div className="d-flex justify-content-center loadingMain">
                        <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                          Product is being fetched
                        </h4>
                        <BeatLoader
                          color={"#36d7b7"}
                          loading={ProductLoading}
                          size={10}
                          className="mt-2 mx-2"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <SubcatProductSection curShowProducts={showProduct} />
                    </>
                  )}
                </>
              ) : (
                <>
                  <SubcatProductSection curShowProducts={showProduct} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
