import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  authActions,
  pageStatusChange,
} from "../../redux/athentication/Athentication";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import moment from "moment/moment";
import { Modal, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "react-bootstrap";

const Wallet = () => {
  const { isAuth, loginData } = useSelector((state) => state.Athentication);
  const [topUpList, setTopUpList] = useState([]);
  const [orderShow, setOrderShow] = useState([]);
  const [tempLoading, setTempLoading] = useState(true);
  const [walletAmount, setWalletAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentId, setpaymentId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllTopUps();
  }, []);
  const getAllTopUps = async () => {
    const url = `${Baseurl}/api/v1/wallet/walletbyclientid/${loginData._id}`;
    console.log(url, "url");
    const resp = await axios.get(url);
    if (resp.data.success) {
      setTopUpList(resp.data.wallet.reverse());
    }
  };
  console.log(topUpList, "topUpList");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const addToWallet = async () => {
    // Amount to be added to wallet
    const amountToAdd = Number(walletAmount);
    if (amountToAdd > 0) {
      // Razorpay options
      var options = {
        key: "rzp_live_fxXGvIYhw9zHxa", // Replace with your Razorpay live key
        amount: amountToAdd * 100, // Razorpay works with paisa
        currency: "INR",
        description: "Add money to wallet",
        image:
          "https://res.cloudinary.com/dh1fsseho/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1669977353/Avatar/avatar2_z6yynb.jpg", // Replace with your logo URL
        name: loginData.Name,
        prefill: {
          email: loginData.Email,
          contact: loginData.Mobile,
          name: loginData.Name,
        },
        theme: { color: "#F37254" },

        // Razorpay handler for successful payment
        handler: function (response) {
          if (response.razorpay_payment_id) {
            const payId = response.razorpay_payment_id;
            setpaymentId(payId);
            console.log(payId, "payId");

            const updateWallet = async () => {
              const formData = {
                ClientId: loginData._id,
                ClientName: loginData.Name,
                Mobile: loginData.Mobile,
                previousAmount: Number(loginData.WalletBalance),
                availableAmount:
                  Number(loginData.WalletBalance) + Number(amountToAdd),
                transactionAmount: amountToAdd,
                PaymentStatus: "Success",
                Type: "Money Add to Wallet",
                PaymentId: payId, // Add payment ID for record-keeping
              };
              const config = {
                Headers: { "Content-Type": "application/json" },
              };
              try {
                const walletRes = await axios.post(
                  `${Baseurl}/api/v1/wallet/new`,
                  formData,
                  config
                );
                if (walletRes.data.success) {
                  const updateData = {
                    WalletBalance: formData.availableAmount,
                  };
                  const config = {
                    Headers: { "Content-Type": "application/json" },
                  };
                  const url = `${Baseurl}/api/v1/client/Clientid/${loginData._id}`;
                  const resp = await axios.put(url, updateData, config);
                  if (resp.data.success) {
                    dispatch(
                      authActions.signin({
                        ...resp.data.client,
                        isAuth: true,
                      })
                    );
                  }
                  getAllTopUps();
                  toast.success("Money added to wallet successfully!!!", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setIsModalOpen(false);
                  setWalletAmount(0);
                }
              } catch (error) {
                toast.error("Error updating wallet", {
                  position: "top-center",
                  autoClose: 1000,
                });
                setIsModalOpen(false);
                setWalletAmount(0);
              }
            };
            updateWallet();
          }
        },
        // Modal dismiss handler for payment failure or cancelation
        modal: {
          ondismiss: function () {
            toast.error("Payment cancelled or failed", {
              position: "top-center",
              autoClose: 1000,
            });
            setIsModalOpen(false);
            setWalletAmount(0);
          },
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } else {
      toast.error("Please enter a valid amount", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const logout = () => {
    dispatch(authActions.signout());
    dispatch(pageStatusChange(0));
    navigate("/");
  };
  console.log(loginData, "loginData");
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  useEffect(() => {
    const fetchOrder = async () => {
      const url = `${Baseurl}/api/v1/order/tenOrderbyclient/${loginData._id}`;
      console.log(url, "url");
      const getOrder = await axios.get(url);
      if (getOrder.data.success) {
        const ordersfetch = getOrder.data.order;
        setTempLoading(false);
        setOrderShow(ordersfetch);
      }
    };

    fetchOrder();
  }, [loginData]);

  return (
    <div
      className="tab-pane fade show"
      id="pills-wallet"
      role="tabpanel"
      aria-labelledby="pills-wallet-tab"
    >
      <main className="main">
        <section className="pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        Your Wallet Balance:{" "}
                        <span
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#ccffcc",
                            border: "1px solid #000",
                            borderRadius: "5px",
                          }}
                        >
                          {loginData?.WalletBalance}
                        </span>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#009900",
                          color: "#fff",
                          padding: "5px 10px",
                          borderRadius: "10px",
                          width: "180px",
                          textAlign: "center",
                        }}
                        onClick={() => showModal()}
                      >
                        Add Money
                      </div>
                    </div>
                    <Table
                      hover
                      responsive
                      striped
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Transaction ID</th>
                          <th>Previous Amt</th>
                          <th>Available Amt</th>
                          <th>TopUp Amt</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                        {topUpList.length > 0 &&
                          topUpList.map((indAmt, index) => (
                            <tr>
                              <th scope="row">
                                {indAmt.createdAt.slice(0, 10)}
                              </th>
                              <td>{indAmt.PaymentId}</td>
                              <td>{indAmt.previousAmount}</td>
                              <td>{indAmt.availableAmount}</td>
                              <td>{indAmt.transactionAmount}</td>
                              <td>{indAmt.PaymentStatus}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <Modal
                    title="Enter Amount"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <Button
                        style={{
                          padding: "10px 15px",
                          color: "#fff",
                          backgroundColor: "#FF0000",
                        }}
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </Button>,
                      <Button
                        style={{
                          padding: "10px 15px",
                          color: "#fff",
                          backgroundColor: "#04681e",
                        }}
                        onClick={(e) => addToWallet(e)}
                      >
                        Proceed To Pay
                      </Button>,
                    ]}
                  >
                    <input
                      type="text"
                      value={walletAmount}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Replace any non-numeric characters with an empty string
                        const numericValue = value.replace(/[^0-9]/g, "");
                        setWalletAmount(numericValue);
                      }}
                    />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Wallet;
