import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
// import Loader from "react-loader-spinner";
import BeatLoader from "react-spinners/BeatLoader";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import {
  addtoCart,
  clearCart,
  decrementCart,
  getCartTotal,
} from "../../redux/Subscription/SubscriptionSlice";
import Description from "./Hotproductdetails/Description";
import RelatedProduct from "../ProductDetails/RelatedProduct";
import SubscriptionRelatedProduct from "../ProductDetails/SubscriptionRelatedProduct";
import { Modal as OtherModal, Button } from "antd";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { DatePicker as OtherDatePicker, Space } from "antd";
import moment from "moment";
import { Calendar } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaMinus, FaPlus } from "react-icons/fa";

const SubscriptionProductDetails = () => {
  const {
    ProductTotal,
    SubscriptionCartItems,
    Cartloading,
    ProductLoading,
    cartTotalAmount,
    logisticsAmount,
    netPayable,
    cartTotalMrp,
  } = useSelector((state) => state.SubscriptionCart);
  const { isAuth, clientid, loginData, currentAddress, address, showGroccery } =
    useSelector((state) => state.Athentication);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);
  const [productifo, setProductinfo] = useState([]);
  const [subCatproduct, setSubProduct] = useState([]);
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(true);
  const [subscriptionType, setSubcriptionType] = useState("Daily");
  const [subscriptionDates, setSubscriptionDates] = useState([]);
  const [subscriptionDatesList, setSubscriptionDatesList] = useState(null);
  const tomorrow = new DateObject().add(1, "days");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const disabledDate = (current) => {
    // Can not select days before today or today itself
    return current && current < moment().endOf("day");
  };
  const handleDateChange = (selectedDates) => {
    const dateArray = selectedDates.map((dateObj) =>
      dateObj.format("DD-MM-YYYY")
    );
    setSubscriptionDates(dateArray);
    setSubscriptionDatesList(dateArray);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const setStartingDate = () => {
      // Set tomorrow's date as the starting date
      const dateString = moment().add(1, "days").format("YYYY-MM-DD");

      // Set the start date in state (assuming setStartDate is a React state setter)
      setStartDate(dateString);

      // Calculate the remaining dates for the month starting from the selected date
      const selectedDate = moment(dateString);
      const daysInMonth = selectedDate.daysInMonth();
      const remainingDates = [];

      // Loop through the remaining days in the selected month
      for (let i = selectedDate.date(); i <= daysInMonth; i++) {
        remainingDates.push(selectedDate.clone().date(i).format("YYYY-MM-DD"));
      }

      // Set the end date to the last item of the remaining dates
      const lastItem = remainingDates[remainingDates.length - 1];
      setEndDate(lastItem); // Assuming setEndDate is a React state setter
      console.log(lastItem, "lastItem");
      console.log(remainingDates, "remainingDates");

      // Set the subscription dates list to the remaining dates
      setSubscriptionDatesList(remainingDates); // Assuming setSubscriptionDatesList is a React state setter
    };
    setStartingDate();
  }, []);
  const handleSubscription = () => {
    console.log(subscriptionDatesList, "subscriptionDatesList");
    if (subscriptionDatesList.length > 0) {
      if (loginData.WalletBalance) {
        if (
          Number(netPayable) * subscriptionDatesList.length <
          Number(loginData?.WalletBalance)
        ) {
          const dateList = subscriptionDatesList.map((ind) => ({
            date: ind,
            isActive: true,
            dateId: ind.slice(5, 10),
          }));
          setSubscriptionDatesList(dateList);
          setIsModalOpen(false);
        } else {
          const requiredAmount =
            Number(netPayable) * subscriptionDatesList.length -
            Number(loginData?.WalletBalance);
          toast.error(
            `Please add Rs.${requiredAmount} in your wallet to place the order`,
            {
              position: "top-center",
              autoClose: 2000,
            }
          );
          setSubscriptionDates([]);
          setIsModalOpen(false);
        }
      } else {
        alert("Please login to subscribe items");
        navigate("/login");
      }
    } else {
      alert("Please select the dates on which you want the order");
    }
  };
  const takeSubscription = async () => {
    if (isAuth) {
      const formData = {
        ClientId: loginData._id,
        ClientName: loginData.Name,
        Mobile: loginData.Mobile,
        WalletAmt: loginData.WalletBalance,
        Email: loginData.Email,
        TotalAmount: cartTotalAmount,
        DeliveryCharge: logisticsAmount,
        NetPayble: netPayable,
        sellerAmount: cartTotalAmount,
        shippingInfo: {
          City: currentAddress.City,
          HNo: currentAddress.HNo,
          StreetDet: currentAddress.StreetDet,
          LandMark: currentAddress.LandMark,
          address: currentAddress.Address,
          state: currentAddress.State,
          country: "India",
          pinCode: currentAddress.Pincode,
          phoneNo: currentAddress.Number,
        },
        PaymentMode: "Wallet",
        PaymentStatus: "Not Paid",
        subscriptionDate: subscriptionDatesList,
        subscriptionType: subscriptionType,
        OrderProducts: {
          ProductId: SubscriptionCartItems[0]?.ProductId,
          ProductName: SubscriptionCartItems[0]?.ProductName,
          CatId: SubscriptionCartItems[0]?.CatId,
          CatName: SubscriptionCartItems[0]?.CatName,
          Brand: SubscriptionCartItems[0]?.Brand,
          ItemName: SubscriptionCartItems[0]?.ItemName,
          Description: SubscriptionCartItems[0]?.Description,
          ImgUrl: SubscriptionCartItems[0]?.ImgUrl,
          Price: SubscriptionCartItems[0]?.Price,
          Qty: SubscriptionCartItems[0]?.Qty,
          TotalAmount: SubscriptionCartItems[0]?.TotalAmount,
          Mrp: SubscriptionCartItems[0]?.Mrp,
          TotalPrice: cartTotalAmount,
          TotalMrp: SubscriptionCartItems[0]?.TotalMrp,
          Discount: SubscriptionCartItems[0]?.Discount,
        },
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subscription/new`;
      const resp = await axios.post(url, formData, config);
      if (resp.data.success) {
        toast.success("Subscription Created Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          dispatch(clearCart());
        }, 3000);
        navigate("/");
      }
    } else {
      alert("Please LogIn InOrder to Book Subscription");
      navigate("/login");
    }
  };
  const onDailyChange = (date, dateString) => {
    if (!dateString) return;
    setStartDate(dateString);
    const selectedDate = moment(dateString);
    const daysInMonth = selectedDate.daysInMonth();
    const remainingDates = [];
    for (let i = selectedDate.date(); i <= daysInMonth; i++) {
      remainingDates.push(selectedDate.clone().date(i).format("YYYY-MM-DD"));
    }
    const lastItem = remainingDates[remainingDates.length - 1];
    setEndDate(lastItem);
    setSubscriptionDatesList(remainingDates);
  };

  const onWeeklyChange = (date, dateString) => {
    if (!dateString) return; // Ensure a valid date is selected
    setStartDate(dateString);
    const selectedDate = moment(dateString); // Create a moment object from the selected date
    const lastDateOfMonth = selectedDate.clone().endOf("month"); // Get the last date of the month
    const remainingDates = [selectedDate.format("YYYY-MM-DD")]; // Start with the selected date

    let currentDate = selectedDate.clone(); // Clone to maintain the original selected date

    // Loop to add dates at +7 day intervals
    while (true) {
      currentDate.add(7, "days"); // Add 7 days
      if (currentDate.isAfter(lastDateOfMonth)) break; // Stop if the date goes beyond the end of the month
      remainingDates.push(currentDate.format("YYYY-MM-DD")); // Add to the array
    }
    const lastItem = remainingDates[remainingDates.length - 1];
    setEndDate(lastItem);
    // Set the constructed dates in your state
    setSubscriptionDatesList(remainingDates);
  };

  const onAlternateChange = (date, dateString) => {
    if (!dateString) return; // Ensure a valid date is selected
    setStartDate(dateString);
    const selectedDate = moment(dateString); // Create a moment object from the selected date
    const lastDateOfMonth = selectedDate.clone().endOf("month"); // Get the last date of the month
    const remainingDates = [selectedDate.format("YYYY-MM-DD")]; // Start with the selected date

    let currentDate = selectedDate.clone(); // Clone to maintain the original selected date

    // Loop to add dates at +7 day intervals
    while (true) {
      currentDate.add(2, "days"); // Add 7 days
      if (currentDate.isAfter(lastDateOfMonth)) break; // Stop if the date goes beyond the end of the month
      remainingDates.push(currentDate.format("YYYY-MM-DD")); // Add to the array
    }
    const lastItem = remainingDates[remainingDates.length - 1];
    setEndDate(lastItem);
    // Set the constructed dates in your state
    setSubscriptionDatesList(remainingDates);
  };
  const addByDefaultPress = async (hotproducts) => {
    const product = hotproducts[0];
    const proindex = 0;
    // Find the default pack size (where Pri is true)
    const packIndex = product.PackSizes.findIndex((pack) => pack.Pri === true);

    if (packIndex === -1) {
      console.error("No pack with Pri === true found.");
      return;
    }
    if (product.PackSizes[packIndex].CartQuantity === 0) {
      try {
        // Clone productinfo and update quantity for the selected pack size
        let products = [...hotproducts];
        products[proindex].PackSizes[packIndex].CartQuantity += 1;

        // Update the productinfo state
        setProductinfo(products);

        // Prepare the form data for adding the product to the cart
        const formData = {
          ProductId: product._id,
          ProductName: product.ItemName,
          CatName: product.Category,
          CatId: product.CatId,
          SubCat: product.SubCat,
          SubCatId: product.SubCatId,
          Brand: product.Brand,
          ItemName: product.ItemName,
          PackSize: product.PackSizes[packIndex].PackSize,
          Description: product.Description,
          ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
          Price: product.PackSizes[packIndex].SellingPrice,
          Qty: 1,
          TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
          Mrp: product.PackSizes[packIndex].Mrp,
          TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
          TotalMrp: product.PackSizes[packIndex].Mrp * 1,
          Discount:
            product.PackSizes[packIndex].Mrp -
            product.PackSizes[packIndex].SellingPrice,
          Cashback: product.Cashback,
          SellingPrice: product.PackSizes[packIndex].SellingPrice,
          GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
          CostPrc: product.PackSizes[packIndex].CostPrc,
          GstCost: product.PackSizes[packIndex].GstCost,
          packsizeId: product.PackSizes[packIndex]._id,
          maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
        };

        // Dispatch the formData to the addtoCart action
        dispatch(addtoCart(formData));
        dispatch(getCartTotal());
      } catch (error) {
        console.error("Error adding to cart", error);
      }
    }
  };
  useEffect(() => {
    try {
      const productslugurl = params.producturl;

      const fetchData = async () => {
        const url = `${Baseurl}/api/v1/subcription-grocery/Grocerybyslugurl/${productslugurl}`;
        const currentProduct = await axios.get(url);

        try {
          if (currentProduct.data.success === true) {
            setLoading(false);
            let productInfo = currentProduct.data.grocery;

            let hotproducts = productInfo;

            SubscriptionCartItems.forEach((cartItem) => {
              let itemIndex = -1;
              itemIndex = hotproducts.findIndex(
                (product) => String(product._id) === String(cartItem.ProductId)
              );
              if (itemIndex >= 0) {
                let packIndex = -1;
                packIndex = hotproducts[itemIndex].PackSizes.findIndex(
                  (option) => String(option._id) === String(cartItem.packsizeId)
                );
                if (packIndex >= 0) {
                  hotproducts[itemIndex].PackSizes[packIndex].CartQuantity =
                    cartItem.Qty;
                }
              }
            });

            await setProductinfo(hotproducts);
            addByDefaultPress(hotproducts);
          }
        } catch (error) {}
      };

      fetchData();

      if (!ProductLoading) {
        const currentProd = ProductTotal.find(
          (product) => product.Url === productslugurl
        );

        if (currentProd !== "") {
          try {
            const currentProduct = ProductTotal.filter(
              (product) => product.Url === productslugurl
            );

            const curSubCatProd = ProductTotal.filter(
              (product) => product.SubCatId === currentProd.SubCatId
            );

            const singlePro = curSubCatProd.filter(
              (product) => product.Single === true
            );
            const offerPro = singlePro.filter(
              (product) => product.Trending === true
            );
            setSubProduct(offerPro);
          } catch (error) {}
        }
      }
    } catch (error) {}
  }, [params.producturl, ProductLoading]);

  const addtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productifo];
      products[itemIndex].PackSizes[packIndex].CartQuantity += 1;
      setProductinfo([...products]);
    } catch (error) {}

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };

    dispatch(addtoCart(formData));
  };
  const decrementtocartPress = async (e, product, itemIndex, packIndex) => {
    try {
      let products = [...productifo];
      products[itemIndex].PackSizes[packIndex].CartQuantity -= 1;
      setProductinfo([...products]);
    } catch (error) {}

    const formData = {
      ProductId: product._id,
      ProductName: product.ItemName,
      CatName: product.Category,
      CatId: product.CatId,
      SubCat: product.SubCat,
      SubCatId: product.SubCatId,
      Brand: product.Brand,
      ItemName: product.ItemName,
      PackSize: product.PackSizes[packIndex].PackSize,
      Description: product.Description,
      ImgUrl: product.PackSizes[packIndex].ImgUrlMbl,
      Price: product.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: product.PackSizes[packIndex].SellingPrice * 1,
      Mrp: product.PackSizes[packIndex].Mrp,
      TotalPrice: product.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: product.PackSizes[packIndex].Mrp * 1,
      Discount:
        product.PackSizes[packIndex].Mrp * 1 -
        product.PackSizes[packIndex].SellingPrice * 1,
      Cashback: product.Cashback,
      SellingPrice: product.PackSizes[packIndex].SellingPrice,
      GstSellPrc: product.PackSizes[packIndex].GstSellPrc,
      CostPrc: product.PackSizes[packIndex].CostPrc,
      GstCost: product.PackSizes[packIndex].GstCost,
      packsizeId: product.PackSizes[packIndex]._id,
      maximumQuantity: product.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  const changePrimery = (productId, packId, proindex) => {
    let product = [...productifo];
    product[proindex].PackSizes = product[proindex].PackSizes.map((pack) => {
      if (pack._id === packId) {
        pack.Pri = true;
      } else {
        pack.Pri = false;
      }

      return pack;
    });

    setProductinfo([...product]);
  };

  console.log(productifo,"productifo");
  

  return (
    <>
      {loading ? (
        <>
          <div className="d-flex justify-content-center loadingMain">
            <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
              Product is being fetched
            </h4>
            <BeatLoader
              color={"#36d7b7"}
              loading={loading}
              size={10}
              className="mt-2 mx-2"
            />
          </div>
        </>
      ) : (
        <>
          <section className="product-section" style={{ marginTop: "10px" }}>
            <div className="container-fluid-lg">
              {productifo.map((data, proindex) => (
                <>
                  <div key={proindex}>
                    {data.PackSizes.map((pack, imgpackIndex) =>
                      pack.Pri === true ? (
                        <>
                          <div className="row" key={imgpackIndex}>
                            <div className="col-xxl-9 col-xl-8 col-lg-7 wow fadeInUp">
                              <div className="row g-4 m-4">
                                <div className="col-xl-6 wow fadeInUp">
                                  <div className="product-left-box">
                                    <div className="row g-2">
                                      <div className="col-xxl-10 col-lg-12 col-md-12">
                                        <div className="product-main-2 no-arrow">
                                          <div>
                                            <div
                                              className="slider-image"
                                              style={{
                                                display: "flex",
                                                display: "-webkit-flex",
                                                justifyContent: "center",
                                                width: "100%",
                                              }}
                                            >
                                              {pack.hasOwnProperty(
                                                "ImgUrlDesk"
                                              ) ? (
                                                <>
                                                  <img
                                                    src={pack.ImgUrlDesk}
                                                    id="img-1"
                                                    data-zoom-image={
                                                      pack.ImgUrlDesk
                                                    }
                                                    className="img-fluid image_zoom_cls-0 blur-up lazyload"
                                                    key={imgpackIndex}
                                                    alt="img"
                                                    width="100%"
                                                  />
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* dfghjkl from hereee */}
                                <div
                                  className="col-xl-6 wow fadeInUp
                                  only_in-desktop_description"
                                  data-wow-delay="0.1s"
                                >
                                  <div className="right-box-contain">
                                    <h6 className="discocontainer offer-top">
                                      {pack.Discount}% Off
                                    </h6>
                                    <h2 className="name">{data.ItemName}</h2>

                                    <div className="price-rating">
                                      <h3
                                        className=" price amounthcontainer"
                                        style={{
                                          color: "#000",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        ₹ {pack.SellingPrice}
                                        {pack.Mrp === pack.SellingPrice ? (
                                          <> </>
                                        ) : (
                                          <>
                                            <del>
                                              <del
                                                className="text-content"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                  color: "#ccc",
                                                }}
                                              >
                                                ₹ {pack.Mrp}
                                              </del>{" "}
                                            </del>
                                          </>
                                        )}
                                        <span className="offer theme-color">
                                          ({pack.Discount}% off)
                                        </span>
                                      </h3>
                                      <div className="product-rating custom-rate">
                                        <ul className="rating">
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStar style={{ color: "gold" }} />
                                          </li>
                                          <li>
                                            <FaStarHalfAlt
                                              style={{ color: "gold" }}
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="procuct-contain">
                                      <p>{data.KeyWords}</p>
                                    </div>

                                    <div className="product-packege">
                                      <div className="product-title">
                                        <h4>Weight</h4>
                                      </div>
                                      <ul className="select-packege">
                                        {data.PackSizes.map(
                                          (pacdata, index) => (
                                            <>
                                              <li
                                                key={index}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  changePrimery(
                                                    data._id,
                                                    pacdata._id,
                                                    proindex
                                                  );
                                                  handleClick(index);
                                                }}
                                              >
                                                <a
                                                  id="color_to_changfe"
                                                  // className={
                                                  //   pacdata.Pri === true
                                                  //     ? "active"
                                                  //     : ""
                                                  // }
                                                  className={
                                                    activeIndex === index
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  {pacdata.PackSize}
                                                </a>
                                              </li>
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </div>

                                    <div className="note-box product-packege">
                                      {productifo[proindex].PackSizes[
                                        imgpackIndex
                                      ].CartQuantity > 0 ? (
                                        <>
                                          {" "}
                                          <div className="cart_qty qty-box product-qty">
                                            <div className="input-group">
                                              <button
                                                type="button"
                                                className="qty-left-minus"
                                                data-type="minus"
                                                data-field
                                                onClick={(e) =>
                                                  decrementtocartPress(
                                                    e,
                                                    data,
                                                    proindex,
                                                    imgpackIndex
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-minus"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                              <input
                                                className="form-control input-number qty-input"
                                                type="text"
                                                name="quantity"
                                                value={
                                                  productifo[proindex]
                                                    .PackSizes[imgpackIndex]
                                                    .CartQuantity
                                                }
                                                contentEditable="false"
                                              />

                                              {9 >=
                                              productifo[proindex].PackSizes[
                                                imgpackIndex
                                              ].CartQuantity ? (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="qty-right-plus"
                                                    data-type="plus"
                                                    data-field
                                                    onClick={(e) =>
                                                      addtocartPress(
                                                        e,
                                                        data,
                                                        proindex,
                                                        imgpackIndex
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="qty-right-plus"
                                                    data-type="plus"
                                                    data-field
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="btn btn-md bg-dark cart-button text-white w-100"
                                            onClick={(e) =>
                                              addtocartPress(
                                                e,
                                                data,
                                                proindex,
                                                imgpackIndex
                                              )
                                            }
                                          >
                                            Add To Cart
                                          </button>
                                        </>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        gap: "50px",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div>
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Subcription 
                                        </div>
                                        <button
                                          style={{
                                            marginTop: "10px",
                                            padding: "5px 10px",
                                            backgroundColor: "#b5fcc8",
                                            borderRadius: "5px",
                                            border: "1px solid #04681e",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                          onClick={() => setIsModalOpen(true)}
                                        >
                                          <span>{subscriptionType}</span>
                                          <span>
                                            <FaPen
                                              style={{ fontSize: "12px" }}
                                            />
                                          </span>
                                        </button>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Start Date
                                        </div>
                                        <button
                                          style={{
                                            marginTop: "10px",
                                            padding: "5px 10px",
                                            backgroundColor: "#b5fcc8",
                                            borderRadius: "5px",
                                            border: "1px solid #04681e",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <span>{startDate}</span>
                                          <span>
                                            <FaPen
                                              style={{ fontSize: "12px" }}
                                            />
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <button
                                      className="mt-2 btn btn-md bg-dark cart-button text-white w-100"
                                      onClick={(e) => takeSubscription()}
                                    >
                                      PLACE ORDER
                                    </button>
                                  </div>
                                </div>

                                {/* to hereeeee */}

                                <div className="description_containerrrr only_in_mobile_version">
                                  {/* <div className="dicount_offff">
                                    <h3>{pack.Discount}% Off</h3>
                                  </div> */}
                                  <div className="total_Descrription_containerrr">
                                    <div className="product-nameeeee">
                                      <h3>{data.ItemName}</h3>
                                    </div>

                                    <div className="description_totallll">
                                      <div>
                                        <ul className="select-packegexxxx">
                                          {data.PackSizes.map(
                                            (pacdata, index) => (
                                              <>
                                                <li
                                                  id="volot"
                                                  className="content_change_the_box"
                                                  key={index}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    changePrimery(
                                                      data._id,
                                                      pacdata._id,
                                                      proindex
                                                    );
                                                    handleClick(index);
                                                  }}
                                                >
                                                  <a
                                                    id="color_to_changfe"
                                                    // className={
                                                    //   pacdata.Pri === true
                                                    //     ? "active"
                                                    //     : ""
                                                    // }
                                                    className={
                                                      activeIndex === index
                                                        ? "active"
                                                        : ""
                                                    }
                                                  >
                                                    {pacdata.PackSize}
                                                  </a>
                                                </li>
                                              </>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="container_to_flexxxxx">
                                        <div className="container_width_flex_cc">
                                          <div>
                                            <span className="pice_bold_Contenttt">
                                              ₹{pack.SellingPrice}
                                            </span>
                                          </div>
                                          <div>
                                            {pack.Mrp === pack.SellingPrice ? (
                                              <></>
                                            ) : (
                                              <>
                                                <span className="normal_contenttttt">
                                                  ₹ {pack.Mrp}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                          <div className="to_off_disss">
                                            <span> {pack.Discount}% Off</span>
                                          </div>
                                        </div>
                                        <div className="btn_tio_adddd">
                                          {productifo[proindex].PackSizes[
                                            imgpackIndex
                                          ].CartQuantity > 0 ? (
                                            <>
                                              <div className="for_incrementinggg">
                                                <button
                                                  className="for_decccc"
                                                  onClick={(e) =>
                                                    decrementtocartPress(
                                                      e,
                                                      data,
                                                      proindex,
                                                      imgpackIndex
                                                    )
                                                  }
                                                >
                                                  <FaMinus />
                                                </button>
                                                <span>
                                                  {
                                                    productifo[proindex]
                                                      .PackSizes[imgpackIndex]
                                                      .CartQuantity
                                                  }
                                                </span>

                                                {9 >=
                                                productifo[proindex].PackSizes[
                                                  imgpackIndex
                                                ].CartQuantity ? (
                                                  <>
                                                    <button
                                                      className="for_increme"
                                                      onClick={(e) =>
                                                        addtocartPress(
                                                          e,
                                                          data,
                                                          proindex,
                                                          imgpackIndex
                                                        )
                                                      }
                                                    >
                                                      <FaPlus />
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <button className="for_increme">
                                                      <FaPlus />
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className="btn_inside_addd"
                                                onClick={(e) =>
                                                  addtocartPress(
                                                    e,
                                                    data,
                                                    proindex,
                                                    imgpackIndex
                                                  )
                                                }
                                              >
                                                Add
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {!isShow ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          gap: "50px",
                                          // marginTop: "15px",
                                        }}
                                      >
                                        <div>
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Subcription 
                                          </div>
                                          <button
                                            style={{
                                              marginTop: "10px",
                                              padding: "5px 10px",
                                              backgroundColor: "#fff",
                                              borderRadius: "5px",
                                              border: "1px solid #fff",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              gap: "5px",
                                              boxShadow:
                                                "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                            }}
                                            onClick={() => setIsShow(true)}
                                          >
                                            <span>{subscriptionType}</span>
                                            <span>
                                              <FaPen
                                                style={{
                                                  fontSize: "12px",
                                                  color: "#00ab66",
                                                }}
                                              />
                                            </span>
                                          </button>
                                        </div>
                                        <div>
                                          <div
                                            style={{
                                              fontSize: "13px",
                                              fontWeight: "700",
                                              marginBottom:".6vh"
                                            }}
                                          >
                                            Start Date
                                          </div>
                                          {subscriptionType === "Daily" && (
                                            <>
                                              <Space direction="vertical">
                                                <OtherDatePicker
                                                  onChange={onDailyChange}
                                                  disabledDate={disabledDate}
                                                />
                                              </Space>
                                            </>
                                          )}
                                          {subscriptionType === "Weekly" && (
                                            <>
                                              <Space direction="vertical">
                                                <OtherDatePicker
                                                  onChange={onWeeklyChange}
                                                  disabledDate={disabledDate}
                                                />
                                              </Space>
                                            </>
                                          )}
                                          {subscriptionType === "Alternate" && (
                                            <>
                                              <Space direction="vertical">
                                                <OtherDatePicker
                                                  onChange={onAlternateChange}
                                                  disabledDate={disabledDate}
                                                />
                                              </Space>
                                            </>
                                          )}
                                          {subscriptionType === "Custom" && (
                                            <>
                                              <DatePicker
                                                multiple
                                                onChange={handleDateChange} // Handle date changes
                                                format="DD-MM-YYYY"
                                                placeholder="Select multiple dates"
                                                minDate={tomorrow}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "300px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            padding: "5px 10px",
                                            backgroundColor: `${
                                              subscriptionType === "Daily"
                                                ? "#e6fff2"
                                                : "#e6e6e6"
                                            }`,
                                            borderRadius: "5px",
                                            border: `${
                                              subscriptionType === "Daily"
                                                ? "1px solid #04681e"
                                                : "1px solid #04681e"
                                            }`,
                                          }}
                                          onClick={() => {
                                            setSubcriptionType("Daily");
                                            setSubscriptionDatesList([]);
                                            setIsShow(false);
                                          }}
                                        >
                                          Daily
                                        </span>
                                        <span
                                          style={{
                                            padding: "5px 10px",
                                            backgroundColor: `${
                                              subscriptionType === "Weekly"
                                                ? "#e6fff2"
                                                : "#e6e6e6"
                                            }`,
                                            borderRadius: "5px",
                                            border: `${
                                              subscriptionType === "Weekly"
                                                ? "1px solid #04681e"
                                                : "1px solid #04681e"
                                            }`,
                                          }}
                                          onClick={() => {
                                            setSubcriptionType("Weekly");
                                            setSubscriptionDatesList([]);
                                            setIsShow(false);
                                          }}
                                        >
                                          Weekly
                                        </span>
                                        <span
                                          style={{
                                            padding: "5px 10px",
                                            backgroundColor: `${
                                              subscriptionType === "Alternate"
                                                ? "#e6fff2"
                                                : "#e6e6e6"
                                            }`,
                                            borderRadius: "5px",
                                            border: `${
                                              subscriptionType === "Alternate"
                                                ? "1px solid #04681e"
                                                : "1px solid #04681e"
                                            }`,
                                          }}
                                          onClick={() => {
                                            setSubcriptionType("Alternate");
                                            setSubscriptionDatesList([]);
                                            setIsShow(false);
                                          }}
                                        >
                                          Alternate
                                        </span>
                                        <span
                                          style={{
                                            padding: "5px 10px",
                                            backgroundColor: `${
                                              subscriptionType === "Custom"
                                                ? "#e6fff2"
                                                : "#e6e6e6"
                                            }`,
                                            borderRadius: "5px",
                                            border: `${
                                              subscriptionType === "Custom"
                                                ? "1px solid #04681e"
                                                : "1px solid #04681e"
                                            }`,
                                          }}
                                          onClick={() => {
                                            setSubcriptionType("Custom");
                                            setSubscriptionDatesList([]);
                                            setIsShow(false);
                                          }}
                                        >
                                          Custom
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <button
                                    className="mt-2 btn btn-md cart-button text-white w-100"
                                    style={{
                                      backgroundColor: "#00ab66",
                                      fontWeight: "700",
                                    }}
                                    onClick={(e) => takeSubscription()}
                                  >
                                    PLACE ORDER
                                  </button>
                                </div>

                                <Description />
                                <div className="col-12"></div>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp">
                              <div className="right-sidebar-box">
                                <div className="pt-25">
                                  <div className="category-menu">
                                    <h3>Trending Products</h3>
                                    <ul className="product-list product-right-sidebar border-0 p-0">
                                      {subCatproduct.map((data, index) => (
                                        <li key={index}>
                                          {data.PackSizes.map((pack) =>
                                            pack.Pri === true ? (
                                              <>
                                                <Link
                                                  to={`/product-info/${data.Url}`}
                                                >
                                                  <div
                                                    className="offer-product"
                                                    key={pack._id}
                                                  >
                                                    <a className="offer-image">
                                                      <img
                                                        src={pack.ImgUrlDesk}
                                                        className="img-fluid blur-up lazyload"
                                                        alt="img"
                                                      />
                                                    </a>
                                                    <div className="offer-detail">
                                                      <div>
                                                        <a>
                                                          <h6 className="name">
                                                            {data.ItemName}
                                                          </h6>
                                                        </a>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "space-between",
                                                            width: "80px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color: "#000",
                                                              fontFamily:
                                                                "Poppins",
                                                            }}
                                                          >
                                                            ₹{pack.SellingPrice}
                                                          </span>
                                                          {pack.Mrp ===
                                                          pack.SellingPrice ? (
                                                            <> </>
                                                          ) : (
                                                            <>
                                                              {" "}
                                                              <h6
                                                                className="price"
                                                                style={{
                                                                  color: "#ccc",
                                                                  fontFamily:
                                                                    "Poppins",
                                                                  textDecorationLine:
                                                                    "line-through",
                                                                }}
                                                              >
                                                                ₹{pack.Mrp}
                                                              </h6>
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Link>
                                              </>
                                            ) : (
                                              <></>
                                            )
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                                {/* Banner Section */}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div key={imgpackIndex}></div>
                        </>
                      )
                    )}
                  </div>
                </>
              ))}
            </div>
          </section>
          <OtherModal
            title="Choose Date"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button
                style={{
                  padding: "10px 15px",
                  color: "#fff",
                  backgroundColor: "#FF0000",
                }}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>,
              <Button
                style={{
                  padding: "10px 15px",
                  color: "#fff",
                  backgroundColor: "#04681e",
                }}
                onClick={(e) => handleSubscription(e)}
              >
                Confirm
              </Button>,
            ]}
          >
            <div>
              Select Subscription Type:
              <div style={{ fontSize: "11px", fontWeight: "700" }}>
                (By default, the last date of the month corresponding to the
                start date will be taken as the end date)
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "300px",
                }}
              >
                <span
                  style={{
                    padding: "5px 10px",
                    backgroundColor: `${
                      subscriptionType === "Daily" ? "#b5fcc8" : "#e6e6e6"
                    }`,
                    borderRadius: "5px",
                    border: `${
                      subscriptionType === "Daily"
                        ? "1px solid #04681e"
                        : "1px solid #04681e"
                    }`,
                  }}
                  onClick={() => {
                    setSubcriptionType("Daily");
                    setSubscriptionDatesList([]);
                  }}
                >
                  Daily
                </span>
                <span
                  style={{
                    padding: "5px 10px",
                    backgroundColor: `${
                      subscriptionType === "Weekly" ? "#b5fcc8" : "#e6e6e6"
                    }`,
                    borderRadius: "5px",
                    border: `${
                      subscriptionType === "Weekly"
                        ? "1px solid #04681e"
                        : "1px solid #04681e"
                    }`,
                  }}
                  onClick={() => {
                    setSubcriptionType("Weekly");
                    setSubscriptionDatesList([]);
                  }}
                >
                  Weekly
                </span>
                <span
                  style={{
                    padding: "5px 10px",
                    backgroundColor: `${
                      subscriptionType === "Alternate" ? "#b5fcc8" : "#e6e6e6"
                    }`,
                    borderRadius: "5px",
                    border: `${
                      subscriptionType === "Alternate"
                        ? "1px solid #04681e"
                        : "1px solid #04681e"
                    }`,
                  }}
                  onClick={() => {
                    setSubcriptionType("Alternate");
                    setSubscriptionDatesList([]);
                  }}
                >
                  Alternate
                </span>
                <span
                  style={{
                    padding: "5px 10px",
                    backgroundColor: `${
                      subscriptionType === "Custom" ? "#b5fcc8" : "#e6e6e6"
                    }`,
                    borderRadius: "5px",
                    border: `${
                      subscriptionType === "Custom"
                        ? "1px solid #04681e"
                        : "1px solid #04681e"
                    }`,
                  }}
                  onClick={() => {
                    setSubcriptionType("Custom");
                    setSubscriptionDatesList([]);
                  }}
                >
                  Custom
                </span>
              </div>
            </div>
            {subscriptionType === "Daily" && (
              <>
                <Space direction="vertical">
                  <OtherDatePicker
                    onChange={onDailyChange}
                    disabledDate={disabledDate}
                  />
                </Space>
                <div style={{ marginTop: "10px" }}>
                  <h6>Selected Dates:</h6>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {subscriptionDatesList &&
                      subscriptionDatesList.map((date, index) => (
                        <span
                          key={index}
                          style={{
                            width: "100px",
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#b3ffb3",
                            margin: "5px",
                            border: "1px solid #04681e",
                          }}
                        >
                          {date}
                        </span>
                      ))}
                  </div>
                </div>
              </>
            )}
            {subscriptionType === "Weekly" && (
              <>
                <Space direction="vertical">
                  <OtherDatePicker
                    onChange={onWeeklyChange}
                    disabledDate={disabledDate}
                  />
                </Space>
                <div style={{ marginTop: "10px" }}>
                  <h6>Selected Dates:</h6>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {subscriptionDatesList.map((date, index) => (
                      <span
                        key={index}
                        style={{
                          width: "100px",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#b3ffb3",
                          margin: "5px",
                          border: "1px solid #04681e",
                        }}
                      >
                        {date}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            )}
            {subscriptionType === "Alternate" && (
              <>
                <Space direction="vertical">
                  <OtherDatePicker
                    onChange={onAlternateChange}
                    disabledDate={disabledDate}
                  />
                </Space>
                <div style={{ marginTop: "10px" }}>
                  <h6>Selected Dates:</h6>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {subscriptionDatesList.map((date, index) => (
                      <span
                        key={index}
                        style={{
                          width: "100px",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#b3ffb3",
                          margin: "5px",
                          border: "1px solid #04681e",
                        }}
                      >
                        {date}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            )}
            {subscriptionType === "Custom" && (
              <>
                <DatePicker
                  multiple
                  onChange={handleDateChange} // Handle date changes
                  format="DD-MM-YYYY"
                  placeholder="Select multiple dates"
                  minDate={tomorrow}
                />

                <div style={{ marginTop: "10px" }}>
                  <h6>Selected Dates:</h6>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {subscriptionDatesList.map((date, index) => (
                      <span
                        key={index}
                        style={{
                          width: "100px",
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#b3ffb3",
                          margin: "5px",
                          border: "1px solid #04681e",
                        }}
                      >
                        {date}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            )}
          </OtherModal>
          {showButton && (
            <div className="sticky-bottom-cart">
              <div className="container-fluid-lg">
                <div className="row">
                  <div className="col-12">
                    <div className="cart-content">
                      <div className="product-image">
                        <img
                          src="../assets/images/product/category/1.jpg"
                          className="img-fluid blur-up lazyload"
                          alt="img"
                        />
                        <div className="content">
                          <h5>Creamy Chocolate Cake</h5>
                          <h6>
                            ₹32.96<del className="text-danger">₹96.00</del>
                            <span>55% off</span>
                          </h6>
                        </div>
                      </div>
                      <div className="selection-section">
                        <div className="form-group mb-0">
                          <select
                            id="input-state"
                            className="form-control form-select"
                          >
                            <option selected disabled>
                              Choose Weight...
                            </option>
                            <option>1/2 KG</option>
                            <option>1 KG</option>
                            <option>1.5 KG</option>
                          </select>
                        </div>
                        <div className="cart_qty qty-box product-qty m-0">
                          <div className="input-group h-100">
                            <button
                              type="button"
                              className="qty-left-minus"
                              data-type="minus"
                              data-field
                            >
                              <i className="fa fa-minus" aria-hidden="true" />
                            </button>
                            <input
                              className="form-control input-number qty-input"
                              type="text"
                              name="quantity"
                              defaultValue={1}
                            />
                            <button
                              type="button"
                              className="qty-right-plus"
                              data-type="plus"
                              data-field
                            >
                              <i className="fa fa-plus" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="add-btn">
                        <a className="btn theme-bg-color text-white wishlist-btn">
                          <i className="fa fa-bookmark" /> Wishlist
                        </a>
                        <a className="btn theme-bg-color text-white">
                          <i className="fas fa-shopping-cart" /> Add To Cart
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {productifo.map((data, proindex) => (
        <>
          <div key={proindex}>
            {data.PackSizes.map((pack, imgpackIndex) =>
              pack.Pri === true ? (
                <SubscriptionRelatedProduct products={data.SubCatId} />
              ) : (
                <></>
              )
            )}
          </div>
        </>
      ))}

      {/* <HotProductSlider /> */}
      {/* <div
        style={{
          marginBottom: "20vh",
        }}
      ></div> */}
    </>
  );
};

export default SubscriptionProductDetails;
