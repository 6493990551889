import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  authActions,
  pageStatusChange,
} from "../../redux/athentication/Athentication";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import moment from "moment/moment";
// import { Modal, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "react-bootstrap";
import { Button, Modal } from "antd";
import { Switch } from "antd";
const Subscription = () => {
  const { isAuth, loginData } = useSelector((state) => state.Athentication);
  const [topUpList, setTopUpList] = useState([]);
  const [orderShow, setOrderShow] = useState([]);
  const [tempLoading, setTempLoading] = useState(true);
  const [walletAmount, setWalletAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentId, setpaymentId] = useState("");
  const [subscription, setSubscription] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedSub, setSelectedSub] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);

  const getAllTopUps = async () => {
    const url = `${Baseurl}/api/v1/subscription/clientId/${loginData._id}`;

    const resp = await axios.get(url);

    if (resp.data.success) {
      setSubscription(resp.data.subscriptions);
    }
  };
  useEffect(() => {
    getAllTopUps();
  }, []);
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  const showModal = (data) => {
    setSelectedSub(data);
    setIsModalOpen(true);
    const getItem = data.subscriptionDate.map((indData) =>
      console.log(indData, "indData")
    );
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //
  const handleActive = async (index) => {
    // Get the selected subscription data
    const getSubscription = { ...selectedSub }; // Create a shallow copy to avoid direct mutation
    // Set the isActive status of the specific subscription date to false
    getSubscription.subscriptionDate[index].isActive = false;

    // Configure headers for the request
    const config = {
      headers: { "Content-Type": "application/json" }, // Corrected 'Headers' to 'headers'
    };

    const url = `${Baseurl}/api/v1/subscription/subscriptionActiveChange/${selectedSub._id}`;

    try {
      const resp = await axios.put(url, getSubscription, config);

      if (resp.data.success) {
        alert(
          `Your subscription of ${selectedSub._id.slice(
            0,
            18
          )} has been cancelled for the selected date`
        );
        setIsModalOpen(false); // Close the modal if applicable
        window.location.reload(); // Reload the page to reflect changes
      } else {
        // Optionally handle the case when the response is not successful
        alert("Failed to cancel the subscription. Please try again.");
      }
    } catch (error) {
      // Handle potential errors from the PUT request
      console.error(error);
      alert(
        "An error occurred while trying to cancel the subscription. Please try again."
      );
    }
  };

  //   const onChange = (checked: boolean) => {
  //     console.log(`switch to ${checked}`);
  //   };

  return (
    <>
      {subscription?.length > 0 ? (
        <>
          <div
            className="tab-pane fade show"
            id="pills-subscription"
            role="tabpanel"
            aria-labelledby="pills-subscription-tab"
          >
            <main className="main">
              <section className="pt-150 pb-150">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 m-auto">
                      <div className="row">
                        <div className="col-md-12">
                          <Table
                            hover
                            responsive
                            striped
                            style={{ marginTop: "20px" }}
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Client Id</th>
                                <th>Client Name</th>
                                <th>Shipping Info</th>
                                <th>Wallet Amount</th>
                                <th>Sub Type</th>
                              </tr>
                            </thead>
                            <tbody
                              style={{ maxHeight: "40vh", overflowY: "scroll" }}
                            >
                              {subscription?.length > 0 &&
                                subscription.map((indAmt, index) => (
                                  <tr key={index}>
                                    <th scope="row">
                                      {indAmt.createdAt.slice(0, 10)}
                                    </th>
                                    <td
                                      style={{
                                        cursor: "pointer",
                                        color: "#00ab66",
                                        fontWeight: "500",
                                      }}
                                      onClick={() => showModal(indAmt)}
                                    >
                                      {indAmt.ClientId.slice(
                                        0,
                                        10
                                      ).toUpperCase()}
                                    </td>
                                    <td>{indAmt.ClientName}</td>
                                    <td>{indAmt.availableAmount}</td>
                                    <td>{indAmt.WalletAmt}</td>
                                    <td>{indAmt.subscriptionType}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          <Modal
                            title="Basic Modal"
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                          >
                            <Table
                              hover
                              responsive
                              striped
                              style={{ marginTop: "20px" }}
                            >
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>IsActive</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedSub &&
                                  selectedSub?.subscriptionDate.length > 0 &&
                                  selectedSub?.subscriptionDate.map(
                                    (indDate, index) => (
                                      <tr key={index}>
                                        <th scope="row">{indDate.date}</th>
                                        <td>
                                          <Switch
                                            defaultChecked={
                                              indDate.isActive ? true : false
                                            }
                                          />
                                        </td>
                                        <td>
                                          {indDate.isActive ? (
                                            <button
                                              onClick={() =>
                                                handleActive(index)
                                              }
                                              style={{
                                                backgroundColor: "#00ab66",
                                                color: "#fff",
                                                padding: "5px",
                                                borderRadius: "5px",
                                                borderColor: "#00ab66",
                                              }}
                                            >
                                              Cancel
                                            </button>
                                          ) : (
                                            <span
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "50px",
                                                border: "2px",
                                              }}
                                            >
                                              ---
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </Table>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Subscription;
