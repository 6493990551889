import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaCheck,
  FaCheckCircle,
  FaChevronUp,
  FaLongArrowAltRight,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import {
  MdCheckCircleOutline,
  MdKeyboardArrowRight,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import { TbHexagon } from "react-icons/tb";
import DeleteIcon from "@mui/icons-material/Delete";
import HexagonIcon from "@mui/icons-material/Hexagon";
import { Button, Drawer, Input, Radio, Space } from "antd";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CloseOutlined } from "@mui/icons-material";
import "./CheckOutmobile.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { newOrder } from "../../redux/order/OrderSlice";
import {
  clearCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import {
  clientUpdate,
  paymentTypeChange,
  walletTransitionPost,
  walletUpdateAfterOrder,
} from "../../redux/athentication/Athentication";
import axios from "axios";
import EmptyCart from "../cart/EmptyCart";

const CheckOutMobile = () => {
  const {
    isAuth,
    address,
    currentAddress,
    addressmodel,
    loginData,
    clientWalletAmount,
    walletUse,
    paymentType,
    clientid,
    name,
    email,
    editaddressmodel,
  } = useSelector((state) => state.Athentication);
  const {
    CartItems,
    cartTotalAmount,
    ProductTotal,
    cartCouponDiscount,
    logisticsAmount,
    netPayable,
    cartTotalMrp,
    CouponDiscountAmount,
    cartTotalPrice,
    updateNetPayable,
    usedWalletAmount,
  } = useSelector((state) => state.Cart);

  console.log(CartItems, "CartItems");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const [openOtp, setOpenOtp] = useState(false);
  const [placementOtp, setPlacementOtp] = useState("bottom");
  const [openAddress, setOpenAddress] = useState(false);
  const [placementAddress, setPlacementAddress] = useState("bottom");

  const [mainerrormassage, setMainErrormassage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [cartamountDetails, setCartamountDetails] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);

  useEffect(() => {
    let cartTotalAmountN = 0;
    let totalAmount = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN =
        Number(cartTotalAmountN) + Number(CartItems[i].TotalAmount);
      cartTotalMrpN = Number(cartTotalMrpN) + Number(CartItems[i].TotalMrp);
      cartTotalPriceN =
        Number(cartTotalPriceN) + Number(CartItems[i].TotalPrice);
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    totalAmount =
      Number(cartTotalAmountN) +
      Number(logisticsAmountN) -
      Number(CouponDiscountAmount);
    netPayableN = Number(totalAmount) - Number(usedWalletAmount);

    const cartAmountsForm = {
      cartTotalAmount: Number(cartTotalAmountN),
      netPayable: Number(netPayableN),
      cartTotalMrp: Number(cartTotalMrpN),
      cartTotalPrice: Number(cartTotalPriceN),
      logisticsAmount: Number(logisticsAmountN),
      totalAmount: Number(totalAmount),
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  const removefromcart = (cartItem) => {
    dispatch(removefromCart(cartItem));
    dispatch(getCartTotal());
  };

  const cashOnDeliveryHandleSubmit = async (e) => {
    if (!btnLoading) {
      setbtnLoading(true);
      const addressString = `${
        currentAddress.HNo +
        "," +
        currentAddress.StreetDet +
        "," +
        currentAddress.AName +
        "," +
        currentAddress.LandMark +
        "," +
        currentAddress.City +
        "," +
        currentAddress.State +
        "," +
        currentAddress.Type +
        "," +
        currentAddress.Pincode
      }`;

      let grandasTotal = 0;
      if (!walletUse) {
        grandasTotal = cartamountDetails.netPayable;
      } else {
        grandasTotal = updateNetPayable;
      }

      let CartItemForNow = CartItems.map((item) => ({
        ...item,
        Brand: item.Brand || "Brand",
      }));

      var date = new Date();
      date.setDate(date.getDate() + 1);
      // console.log(cartamountDetails, "totalAmount");
      if (currentAddress !== "" && cartamountDetails.cartTotalAmount > 0) {
        const orderData = {
          ClientId: clientid,
          ClientName: name,
          TotalAmount: Number(cartamountDetails.cartTotalAmount),
          Email: email,
          DeliveryCharge: logisticsAmount,
          wallet: usedWalletAmount,
          GrandTotal:
            Number(cartamountDetails.cartTotalAmount) -
            Number(usedWalletAmount),
          Address: addressString,
          AreaName: currentAddress.StreetDet,
          Mobile: loginData.Mobile,
          PaymentStatus: "COD",
          PaymentMode: "COD",
          ExpectedDelDate: date,
          ProductCount: CartItemForNow.length,
          Saving:
            cartamountDetails.cartTotalMrp +
            CouponDiscountAmount -
            cartamountDetails.cartTotalPrice,
          Cashback: "0",
          CurrentStatus: "Not Paid",
          TxnId: "NA",
          couponDetails: {},
          OrderProducts: CartItemForNow,
        };
        // console.log(orderData, "orderData");
        const order = await dispatch(newOrder(orderData));
        console.log(order.payload, "order.payload");

        if (!order) {
          console.log("not able to book your order");
        } else {
          if (order.payload.success) {
            dispatch(clearCart());
            const orderId = order.payload.order;
            const userUpdateForm = {
              claintid: loginData._id,
              WalletBalance: loginData.WalletBalance - usedWalletAmount,
            };

            const client = await dispatch(clientUpdate(userUpdateForm));

            dispatch(
              walletUpdateAfterOrder(loginData.WalletBalance - usedWalletAmount)
            );

            const walletForm = {
              ClientId: loginData._id,
              previousAmount: loginData.WalletBalance,
              availableAmount: loginData.WalletBalance - usedWalletAmount,
              transactionAmount: usedWalletAmount * -1,
              orderId: orderId._id,
              Type: "Wallet debit on New Order",
              ClientName: loginData.Name,
              Mobile: loginData.Mobile,
            };

            dispatch(walletTransitionPost(walletForm));

            const sendOptthowwp = async () => {
              const mobile = loginData.Mobile;
              let data = JSON.stringify({
                countryCode: "+91",
                phoneNumber: mobile,
                callbackData: "some text here",
                type: "Template",
                template: {
                  name: "order_placed_prepaid",
                  languageCode: "en",
                  bodyValues: [name],
                },
              });

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.interakt.ai/v1/public/message/",
                headers: {
                  Authorization:
                    "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {})
                .catch((error) => {
                  console.log(error);
                });
            };
            sendOptthowwp();

            toast.success("ORDER PLACED SUCCESSFULL", {
              position: "top-center",
              autoClose: 500,
            });
            navigate("/order-success");
          } else {
            toast.error("ORDER NOT PLACED SUCCESSFULL", {
              position: "top-center",
              autoClose: 2000,
            });
            setbtnLoading(false);
          }
        }
      } else if (cartamountDetails.cartTotalAmount === 0) {
        toast.error("Please Check The Cart Products", {
          position: "top-center",
          autoClose: 2000,
        });
      } else if (currentAddress === "") {
        toast.error("Please Add Address", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    }
  };

  const payTmPayHandleSubmit = async (e) => {
    toast.error("Please Select COD", {
      position: "top-center",
      autoClose: 2000,
    });
  };

  const showDrawerAddress = () => {
    setOpenAddress(true);
  };
  const onCloseAddress = () => {
    setOpen(false);
  };
  const onChangeAddress = (e) => {
    setPlacementAddress(e.target.value);
  };
  const showDrawerOtp = () => {
    setOpenOtp(true);
  };
  const onCloseOtp = () => {
    setOpenOtp(false);
  };
  const onChangeOtp = (e) => {
    setPlacementOtp(e.target.value);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  // for otp
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const handleInputChange = (index, value) => {
    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    } else if (!value && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <>
      {CartItems.length > 0 ? (
        <>
          <>
            <div className="check_out">
              <div className="check_inside">
                <div className="navcontent">
                  <div className="sub_nav_price">
                    <div className="sub_imgaes">
                      <Link to="/cart">
                        <div>
                          <FaArrowLeft />
                        </div>
                      </Link>
                      <div>
                        <img
                          src="../assets/images/kisanlogo.png"
                          alt="logo"
                          className="img_sub"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="pricessmoney">
                        <span className="selling_price_check">
                          ₹
                          {Number(cartamountDetails.totalAmount) -
                            Number(usedWalletAmount)}{" "}
                        </span>
                        <span className="mrp_price_check">
                          ₹{Number(cartamountDetails.cartTotalMrp)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="total_check_out"
                style={{ overflow: "scroll", height: "90vh" }}
              >
                <div>
                  <div className="order_summary_cart">
                    <div className="sub_order_sum">
                      <div className="sub_orderrrrr">
                        <div className="order_pageee">
                          <span>
                            <IoCartOutline />
                          </span>
                          <span className="order_text">Order Summary</span>
                        </div>
                        <div className="two_order_pagee">
                          <span className="number_items">
                            {" "}
                            {CartItems.length} Items
                          </span>
                          <span>
                            <FaChevronUp />
                          </span>
                        </div>
                      </div>
                      <div className="cart_itemsssss">
                        {CartItems.map((cart, index) => (
                          <div className="sing_carttt" key={index}>
                            <div className="sub_img_conta">
                              <div className="img_containnerr">
                                <img
                                  src={cart.ImgUrl}
                                  alt="error"
                                  className="img_cart_img"
                                />
                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <div>
                                  {/* <h3 className="name_products">{cart.ItemName}</h3> */}
                                  <h3 className="name_products">
                                    {cart.ItemName.length > 20
                                      ? `${cart.ItemName.slice(0, 20)}...`
                                      : cart.ItemName}
                                  </h3>
                                </div>
                                <div>
                                  <p className="sunb_content">
                                    {Number(cart.Qty)} X ₹{Number(cart.Price)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className="delte_iconn"
                                onClick={() => removefromcart(cart)}
                              >
                                <DeleteIcon className="delteeeee" />
                              </div>
                              <div className="pricess_cart">
                                <span className="actual_price">
                                  ₹{cart.Mrp}
                                </span>
                                <span className="selling_price_dis">
                                  ₹{cart.Price}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="sub_total_price">
                        <div className="inner_sub_tottal">
                          <div className="inner_sub_priii">
                            <span className="sub_total_">Total MRP</span>
                            <span className="pricreee">
                              ₹{cartamountDetails.cartTotalMrp}
                            </span>
                          </div>
                          <div className="inner_sub_priii">
                            <span className="sub_total_">Discount on MRP</span>
                            <span className="discount_sub">
                              - ₹{Number(cartCouponDiscount)}
                              {/* {Number(cartamountDetails.cartTotalMrp) -
                        (Number(cartamountDetails.totalAmount) -
                          Number(usedWalletAmount))} */}
                            </span>
                          </div>
                          <div className="inner_sub_priii">
                            <span className="sub_total_">Delivery Fee</span>
                            <span>₹{cartamountDetails.logisticsAmount}</span>
                          </div>
                        </div>
                        <div className="total_summmty">
                          <div className="total_Spell">Net Payable</div>
                          <div className="total_price">
                            ₹
                            {Number(cartamountDetails.totalAmount) -
                              Number(usedWalletAmount)}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="checkout-box check-out-titlllee_model"
                  id="payment_designnn"
                >
                  <div className="checkout-title ">
                    <h5 style={{ color: "#000" }}>Payment Option</h5>
                  </div>
                  <div
                    className="checkout-detail mt-3"
                    id="toflexxx_check_outttt"
                  >
                    <div
                      className="accordion accordion-flush custom-accordion"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id="flush-headingFour"
                        >
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="cash"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="cash"
                                  checked={paymentType === "CashOnDelivery"}
                                  onChange={(e) => {
                                    dispatch(
                                      paymentTypeChange("CashOnDelivery")
                                    );
                                  }}
                                />
                                Cash On Delivery
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="credit"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="credit"
                                  checked={paymentType === "paytmPayment"}
                                  onChange={(e) => {
                                    dispatch(paymentTypeChange("paytmPayment"));
                                  }}
                                />
                                Pay Now
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="coupon_content">
                  <div className="sub_coupon">
                    <div className="inner_coupon">
                      <div>
                        <HexagonIcon className="hextshss" />
                      </div>
                      <div className="toflex_coupom">
                        <span className="green_content">
                          12 % off Upto ₹80.00 with FESTIVE12
                        </span>
                        <span className="two_couiuu">
                          2 Coupons Avaible <ArrowRightIcon />{" "}
                        </span>
                      </div>
                    </div>
                    <div className="apply_content">Apply</div>
                  </div>
                </div> */}
                {/* this is for the mobile number */}
                {btnLoading === true ? (
                  <>
                    <div className="add_addressss">
                      <button className="bnt_addre"> Place Order</button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="add_addressss"
                      onClick={
                        paymentType === "CashOnDelivery"
                          ? cashOnDeliveryHandleSubmit
                          : payTmPayHandleSubmit
                      }
                    >
                      <button className="bnt_addre"> Place Order</button>
                    </div>
                  </>
                )}

                {/* this is for the otp */}
                {/* <div className='add_addressss'>
                    <button className='bnt_addre' onClick={showDrawerOtp}>ADD Address</button>
                </div> */}

                {/* to add the address */}
                {/* <div className='add_addressss'>
                    <button className='bnt_addre' onClick={showDrawerAddress}>ADD Address</button>
                </div> */}

                <div className="down_contemt_of_add">
                  <p className="keep_mmee">
                    keep me pasted about sales and offers
                  </p>
                </div>

                {/* This Code for the Mobile NUMBER */}
                <Drawer
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "1.2rem",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span>Enter Your Mobile Number</span>
                      <span style={{ cursor: "pointer" }} onClick={onClose}>
                        <CloseOutlined />
                      </span>
                    </div>
                  }
                  placement={placement}
                  closable={false}
                  onClose={onClose}
                  open={open}
                  key={placement}
                  height={200}
                  drawerStyle={{
                    borderTopRightRadius: "16px",
                    borderTopLeftRadius: "16px",
                    // height: '200px'
                  }}
                  bodyStyle={{ padding: "16px" }}
                >
                  <div className="center_container">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "2px solid #22B6A8",
                        padding: "8px",
                        borderRadius: "8px",
                        width: "80%",
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                        alt="Indian Flag"
                        style={{
                          width: "30px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />
                      <span style={{ marginRight: "10px" }}>+91</span>
                      <Input
                        placeholder="Enter your mobile number"
                        bordered={false}
                        style={{ flex: 1 }}
                      />
                    </div>

                    <div className="verified_cardddd">
                      <button className="verify_btn">
                        <span>Verify</span>
                        <span>
                          <FaLongArrowAltRight />
                        </span>
                      </button>
                    </div>
                  </div>
                </Drawer>

                {/* This code for the Otp number */}

                <Drawer
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "1.2rem",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span>Enter OTP Number</span>
                      <span style={{ cursor: "pointer" }} onClick={onCloseOtp}>
                        <CloseOutlined />
                      </span>
                    </div>
                  }
                  placement={placementOtp}
                  closable={false}
                  onClose={onCloseOtp}
                  open={openOtp}
                  key={placementOtp}
                  height={200}
                  drawerStyle={{
                    borderTopRightRadius: "16px",
                    borderTopLeftRadius: "16px",
                    height: "200px",
                  }}
                  bodyStyle={{ padding: "16px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="total_otp">
                      <div className="constsiner_sty">
                        <Input
                          id="otp-input-0"
                          value={otp1}
                          onChange={(e) => {
                            setOtp1(e.target.value.slice(0, 1));
                            handleInputChange(0, e.target.value);
                          }}
                          className="inputStylesssss"
                          maxLength={1}
                        />
                        <Input
                          id="otp-input-1"
                          value={otp2}
                          onChange={(e) => {
                            setOtp2(e.target.value.slice(0, 1));
                            handleInputChange(1, e.target.value);
                          }}
                          className="inputStylesssss"
                          // style={inputStyle}
                          maxLength={1}
                        />
                        <Input
                          id="otp-input-2"
                          value={otp3}
                          onChange={(e) => {
                            setOtp3(e.target.value.slice(0, 1));
                            handleInputChange(2, e.target.value);
                          }}
                          className="inputStylesssss"
                          maxLength={1}
                        />
                        <Input
                          id="otp-input-3"
                          value={otp4}
                          onChange={(e) => {
                            setOtp4(e.target.value.slice(0, 1));
                            handleInputChange(3, e.target.value);
                          }}
                          className="inputStylesssss"
                          maxLength={1}
                        />
                        <Input
                          id="otp-input-4"
                          value={otp5}
                          onChange={(e) => {
                            setOtp5(e.target.value.slice(0, 1));
                            handleInputChange(4, e.target.value);
                          }}
                          className="inputStylesssss"
                          maxLength={1}
                        />
                        <Input
                          id="otp-input-5"
                          value={otp6}
                          onChange={(e) => {
                            setOtp6(e.target.value.slice(0, 1));
                            handleInputChange(5, e.target.value);
                          }}
                          className="inputStylesssss"
                          maxLength={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="verified_cardddd">
                    <button className="verify_btn">
                      <span>Verify</span>
                      <span>
                        <FaLongArrowAltRight />
                      </span>
                    </button>
                  </div>
                </Drawer>

                {/* for address drawer */}

                <Drawer
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "1.2rem",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span>Add new address</span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={onCloseAddress}
                      >
                        <CloseOutlined />
                      </span>
                    </div>
                  }
                  placement={placementAddress}
                  closable={false}
                  onClose={onCloseAddress}
                  open={openAddress}
                  key={placementAddress}
                  height={550}
                  drawerStyle={{
                    borderTopRightRadius: "16px",
                    borderTopLeftRadius: "16px",
                  }}
                  bodyStyle={{ padding: "16px" }}
                >
                  <div>
                    <div className="colorforthat">
                      <p className="bold_text1">
                        Use <span className="weight_bold_text1">"BIG12"</span>{" "}
                        to Get Extra{" "}
                        <span className="weight_bold_text1">12 % 0FF</span>
                      </p>
                    </div>

                    <div>
                      <div>
                        <div class="personal-details-container">
                          <div class="container-header">
                            <p className="for_personal">Personal Details</p>
                          </div>
                          <div className="for_labels">
                            <span className="phone_nummmm">Phone Number</span>
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <div className="mobile_number_Containe">
                            <div className="flag_Connn">
                              <img
                                src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                                alt="India Flag"
                                className="flgicom"
                              />
                              <span className="down_Arroe">▼</span>
                            </div>

                            <div className="phone_numberrrr_input_con">
                              <span style={{ marginRight: "5px" }}>+91</span>
                              <input
                                type="tel"
                                className="phone_numberrrr_input"
                              />
                            </div>

                            <div style={{ marginLeft: "10px" }}>
                              <FaCheckCircle className="style_for_check" />
                            </div>
                          </div>
                          <div className="for_flex_con">
                            <div>
                              {" "}
                              <input type="checkbox" id="checkbox" />
                            </div>
                            <div>
                              <p className="for_keepsss">
                                Keep me Posted about Sales and Offers
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              <div>
                                <div className="for_labels">
                                  <span className="phone_nummmm">
                                    Full Name
                                  </span>
                                  <span style={{ color: "red" }}>*</span>
                                </div>
                                <div>
                                  <div className="input-containerrrrd">
                                    <input type="text" id="name" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div className="for_labels">
                                  <span className="phone_nummmm">
                                    Alternative Mobile Number
                                  </span>
                                </div>
                                <div>
                                  <div className="input-containerrrrd">
                                    <input type="text" id="name" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="personal-details-containersss">
                          <div class="container-headersss">
                            <p className="for_personal">Address</p>
                          </div>
                          <div className="for_flexss_container">
                            <div>
                              <div className="for_labels">
                                <span className="phone_nummmm">House No</span>
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div>
                                <div className="input-containerrrrd">
                                  <input type="text" id="name" />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="for_labels">
                                <span className="phone_nummmm">Street</span>
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div>
                                <div className="input-containerrrrd">
                                  <input type="text" id="name" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="for_labels">
                              <span className="phone_nummmm">Address</span>
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className="textarea-containerrr">
                              <textarea
                                id="description"
                                // placeholder="Enter your description here..."
                                rows="3"
                              />
                            </div>
                          </div>
                          <div className="for_flexss_container">
                            <div>
                              <div className="for_labels">
                                <span className="phone_nummmm">Land Mark</span>
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div>
                                <div className="input-containerrrrd">
                                  <input type="text" id="name" />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="for_labels">
                                <span className="phone_nummmm">Area</span>
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div>
                                <div className="input-containerrrrd">
                                  <input type="text" id="name" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="for_flexss_container">
                            <div>
                              <div className="for_labels">
                                <span className="phone_nummmm">State</span>
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div>
                                <div className="input-containerrrrd">
                                  <input type="text" id="name" />
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="for_flexss_container">
                            <div>
                              <div className="for_labels">
                                <span className="phone_nummmm">PinCode</span>
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div>
                                <div className="input-containerrrrd">
                                  <input type="text" id="name" />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="for_labels">
                                <span className="phone_nummmm">City</span>
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div>
                                <div className="input-containerrrrd">
                                  <input type="text" id="name" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="saved_Address_details">
                          <div className="sub_Saved_Add">
                            <p className="save_text">Save Address as</p>
                          </div>
                          <div className="sub_content_save">
                            <div className="home_content">
                              <span className="click_icon">
                                <FaCheck />
                              </span>
                              <span className="addres_De">Home</span>
                            </div>
                            <div className="home_content_border">
                              <span className="addres_De">Work</span>
                            </div>
                            <div className="home_content_border">
                              <span className="addres_De">Other</span>
                            </div>
                          </div>
                          <div className="chec_address">
                            <div>
                              <input type="checkBox" name="" id="" />
                            </div>
                            <div>
                              <p>Save this address across Shopflo Checkouts</p>
                            </div>
                          </div>

                          <div className="save_and_Continuous_btn">
                            <button className="for_sabe">
                              Save and continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Drawer>
              </div>
            </div>
          </>
        </>
      ) : (
        <>
          <section className="section-404 section-lg-space">
            <div className="container-fluid-lg">
              <div className="row">
                <div className="col-12">
                  <div className="image-404">
                    <img
                      src="../assets/images/inner-page/emptycart.png"
                      className="img-fluid blur-up lazyload"
                      alt
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="contain-404">
                    <Link to="/">
                      <button className="btn btn-md text-white theme-bg-color mt-4 mx-auto">
                        Back To Home Screen
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default CheckOutMobile;
