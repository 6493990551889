import React, { useEffect, useState, useRef } from "react";
import "./FillCart.css";
import {
  MdCheckCircleOutline,
  MdKeyboardArrowRight,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import { GoClockFill } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { LuMinus } from "react-icons/lu";
import { RiCoupon2Fill } from "react-icons/ri";
import { FaCheck, FaRegFileAlt } from "react-icons/fa";
import { GiScooter, GiTakeMyMoney } from "react-icons/gi";
import { BiMessageDots } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { Link, unstable_HistoryRouter, useNavigate } from "react-router-dom";
import {
  addtoCart,
  decrementCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import { Navigation } from "swiper/modules";
import { Button, Drawer, Radio, Space } from "antd";
import RightSideCheckOut2 from "../CheckOut/RightSideCheckout2";

const FillCart = () => {
  const {
    CartItems,
    cartTotalAmount,
    ProductTotal,
    cartCouponDiscount,
    cartTotalMrp,
    cartTotalPrice,
  } = useSelector((state) => state.Cart);

  const { isAuth } = useSelector((state) => state.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cartamountDetails, setCartamountDetails] = useState("");

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");


  const handledecreasequtcart = (cartItem) => {
    dispatch(decrementCart(cartItem));
    dispatch(getCartTotal());
  };

  const handleincreasequtcart = (cartItem) => {
    dispatch(addtoCart(cartItem));
    dispatch(getCartTotal());
  };

  useEffect(() => {
    let cartTotalAmountN = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN = cartTotalAmountN + CartItems[i].TotalAmount;
      cartTotalMrpN = cartTotalMrpN + CartItems[i].TotalMrp;
      cartTotalPriceN = cartTotalPriceN + CartItems[i].TotalPrice;
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    netPayableN = cartTotalAmountN + logisticsAmountN;

    const cartAmountsForm = {
      cartTotalAmount: cartTotalAmountN,
      netPayable: netPayableN,
      cartTotalMrp: cartTotalMrpN,
      cartTotalPrice: cartTotalPriceN,
      logisticsAmount: logisticsAmountN,
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);
  //new drawer
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems, dispatch]);

  return (
    <div>
      <div className="MainPartOfCartPage">
        <div className="NavofCartpage">
          <div className="navpart1">
            <span className="iconn" onClick={() => window.history.back()}>
              <MdOutlineArrowBackIos className="iconnn" />
            </span>
            <span className="yourcarttextt">Your Cart</span>
            <span className="savedmoney">
              SAVED <b id="boldtexttt">₹{cartTotalMrp - cartTotalPrice}</b>
            </span>
          </div>
          <div className="navpart2">
            <span>
              <MdCheckCircleOutline className="circleIconn" />
              <b className="colorchange">
                Saved ₹{cartTotalMrp - cartTotalPrice}
              </b>
              including <b>₹{299 - cartTotalPrice} </b> through free delivery!
            </span>
          </div>
        </div>
        <div className="ContainerPartofCartPage">
          <div className="container1">
            {/* <div className="Containerpart1">
              <span className="clockContainer">
                <GoClockFill className="clockIconn" />
              </span>
              <span className="deliveryText">Your order is on the way!</span>
            </div> */}
            {CartItems &&
              CartItems.map((cart, index) => (
                <div className="Containerpart2" key={index}>
                  <div className="imageAndName">
                    <div className="imgContainer">
                      <img src={cart.ImgUrl} alt="" className="imagee" />
                    </div>
                    <div className="aboutImgText" style={{ marginTop: "10px" }}>
                      <h6 style={{ fontFamily: "Poppins", color: "#000" }}>
                        {cart.ItemName}
                      </h6>
                      <p
                        style={{
                          paddingTop: "6px",
                          color: "#ccc",
                          fontFamily: "Poppins",
                        }}
                      >
                        {cart.PackSize}
                      </p>
                    </div>
                  </div>
                  <div className="addorRemove">
                    <span
                      className="symb"
                      onClick={() => handledecreasequtcart(cart)}
                    >
                      <LuMinus id="coloring" />
                    </span>
                    <span style={{ color: "#fff" }}>{cart.Qty}</span>
                    <span
                      className="symb"
                      onClick={() => handleincreasequtcart(cart)}
                    >
                      <IoMdAdd id="coloring" />
                    </span>
                  </div>
                  <div className="Pricepart" style={{ marginLeft: "10px" }}>
                    <h6
                      style={{
                        paddingTop: "9px",
                        color: "#000",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      ₹{cart.Price}
                    </h6>
                    <p
                      style={{
                        paddingTop: "9px",
                        color: "#ccc",
                        fontFamily: "Poppins",
                      }}
                    >
                      ₹{cart.Mrp}
                    </p>
                  </div>
                </div>
              ))}

            {/*  */}
            <div className="Containerpart3">
              <div className="dottedline"> </div>
              <div className="textContainerrr">
                <span className="missText">Missed something?</span>
                <Link to="/">
                  <div className="addmorebtn">
                    <span>
                      <IoMdAdd className="addIconnn" /> Add More Items
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="container2">
            <div className="cont2part1">
              <h6>₹{cartTotalMrp - cartTotalPrice}</h6>
              <span>saved with</span>
              <RiCoupon2Fill className="passIcon" />
            </div>
            <div className="cont2part2">
              <span>
                <span className="goldcoloring">
                  <FaCheck />
                </span>
                <b>Free delivery</b>savings of{" "}
                <b className="goldcoloring">₹{299 - cartTotalPrice}</b>
              </span>
            </div>
            <div className="cont2part2">
              <span>
                <span className="goldcoloring">
                  <FaCheck />
                </span>
                <b>Extra discount</b>above ₹299
                {/* <b className="goldcoloring">₹12</b> */}
              </span>
            </div>
          </div>
          <div className="container3">
           
            <div className="cont3subPart">
              <div className="moneyIconAndTipText">
                <span>
                  <FaRegFileAlt />
                </span>
                <div className="tipText" onClick={showDrawer}>
                  <span>
                    <b>To Pay</b>
                  </span>
                  <span>Incl.all texes and charges</span>
                </div>
              </div>
              <div className="Savinggs">
                <div className="priceeee">
                  <span>
                    <span>₹{cartamountDetails.cartTotalMrp}</span>
                    &nbsp; <b>₹{cartamountDetails.cartTotalAmount}</b>
                  </span>
                  <span className="savingmoneyyy">
                    saving ₹{cartTotalMrp - cartTotalPrice}
                  </span>
                </div>
                <span>
                  <MdKeyboardArrowRight className="ArrowIcon" />
                </span>
              </div>
            </div>
           
            <div className="maindrawer">
              <Space className="d-hidden">
                <Radio.Group
                  value={placement}
                  className="d-none"
                  onChange={onChange}
                >
                  <Radio value="bottom">bottom</Radio>
                </Radio.Group>
              </Space>
              <Drawer
                placement={placement}
                width={"100%"}
                onClose={onClose}
                height={310}
                open={open}
              >
                <RightSideCheckOut2 />
              </Drawer>
            </div>
          </div>
          {/*  */}
          <div className="conatiner333"></div>
        </div>
        {isAuth === true ? (
          <>
            <div className="footerPartofCartPage">
              <Link to="/checkout">
                <div className="btnContainerr">
                  <button style={{ color: "#fff", fontFamily: "Poppins" }}>
                    Proceed to Checkout
                  </button>
                </div>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="footerPartofCartPage">
              <Link to="/login">
                <div className="btnContainerr">
                  <button style={{ color: "#fff", fontFamily: "Poppins" }}>
                    Proceed to Checkout
                  </button>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FillCart;
