import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Route,
  Routes,
  HashRouter,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./component/layout/MainLayout";
import Home from "./pages/Home";
import { getCategory } from "./redux/category/CategorySlice";
import { getSubCategory } from "./redux/subCategory/SubCategorySlice";
import { getCartTotal, getProduct, updateCart } from "./redux/cart/CartSlice";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";
import Cart from "./pages/Cart";
import CheckOut from "./pages/CheckOut";
import OrderSuccess from "./pages/OrderSuccess";
import Error404 from "./pages/Error404";
import ProductDetails from "./pages/ProductDetails";
import OtpScreen from "./pages/Login/OtpScreen";
import Login from "./pages/Login";
import Registation from "./pages/Registation";
import Account from "./component/account/Account";
import {
  getSubCatSmallBanners,
  getSuperCatBanners,
} from "./redux/AllBanners/AllBannersSlice";
import { getSubCatBanners } from "./redux/subcatSlider/SubCatSlider";
import { getTodayFeaturerBanners } from "./redux/AllBanners/TodayFeatureSlice";
import DashBoard from "./component/account/DashBoard";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import ReturnRefund from "./pages/ReturnRefund";
import TermsConditions from "./pages/TermsConditions";
import AboutUs from "./pages/AboutUs";
import OrderTracking from "./pages/OrderTracking";
import Wishlist from "./pages/Wishlist";
import HomeCategorySearch from "./pages/HomeCategorySearch";
import { getOrderbyClId } from "./redux/order/OrderSlice";
import HomeSliderProducts from "./pages/HomeSliderProducts";
import HomeProductSerch from "./pages/HomeProductSerch";
import HTBMainPage from "./pages/HTBMainPage";
import { Baseurl } from "./config/BaseUrl";
import axios from "axios";
import ContactUs from "./pages/ContactUs";
import {
  authActions,
  getClientByMob,
  noaddressUpdate,
  showGroceryStatus,
} from "./redux/athentication/Athentication";
import Subcategoey from "./pages/Home/Subcategoey";
import FailedPaymentStatus from "./pages/paymentStatus/FailedPaymentStatus";
import SuccessPaymentStatus from "./pages/paymentStatus/SuccessPaymentStatus";
import PendingPaaymentStatus from "./pages/paymentStatus/PendingPaaymentStatus";
import SmsSend from "./pages/SmsSend";
import HotProductDetails from "./pages/Home/HotProductDetails";
import CartDemo from "./pages/cart/CartDemo";
import LoginScreen from "./pages/Login/LoginScreen";
import CategoryScreen from "./pages/subCategory/CategoryScreen";
import LeftSideCheckout from "./pages/CheckOut/LeftSideCheckout";
import { OrderListMobile } from "./component/account/dashboard/OrderListMobile";
import OrderScreen from "./pages/OrderScreen";
import AddressList from "./pages/AddressList";
import SubscriptionProductDetails from "./pages/Home/SubscriptionProductInfo";

function App() {
  const dispatch = useDispatch();
  const { isAuth, clientid, loginData, address, showGroccery } = useSelector(
    (state) => state.Athentication
  );
  const {
    isCategoryAvailable,
    isCategoryLoading,
    categoryTotal,
    isSubCategoryAvailable,
    isSubCategoryLoading,
    subCategoryTotal,
  } = useSelector((state) => state.Category);
  const {
    isBannerAvailable,
    isBannerLoading,
    VegBannerTotal,
    isSubCatSmallBannersAvailable,
    isSubCatSmallBannersLoading,
    VegSubcatBannerTotal,
  } = useSelector((state) => state.HomeBanner);
  const { isSuCatSliderAvailable, isSliderLoading, suCatSliderTotal } =
    useSelector((state) => state.SubCategorySlider);
  const {
    isTodayFeaturerAvailable,
    isTodayFeaturerLoading,
    TodayFeatureTotal,
  } = useSelector((state) => state.TodayFeature);
  const { isProductAvailable, isProductLoading, ProductTotal, CartItems } =
    useSelector((state) => state.Cart);

  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  useEffect(() => {
    const getData = async () => {
      const url = `${Baseurl}/api/v1/massage/getmassage`;

      const respo = await axios.get(url);
      if (respo.data.success === true) {
        const respoData = respo.data.message;
        dispatch(showGroceryStatus(respoData));
      }
    };
    getData();
  }, []);

  const replaceItemAtIndex = (array, index, newItem) => {
    if (index >= 0 && index < array.length) {
      return [...array.slice(0, index), newItem, ...array.slice(index + 1)];
    } else {
      console.error("Index out of bounds");
      return array;
    }
  };

  const updateCartWithCurrentPrices = async (CartItems) => {
    let updatedCartItems = [...CartItems];
    for (let index = 0; index < CartItems.length; index++) {
      let currentPrices = "";
      const cartItem = CartItems[index];
      const url = `${Baseurl}/api/v1/grocery/grocerybyid/${cartItem.ProductId}`;
      const resp = await axios.get(url);

      if (resp.data.success) {
        currentPrices = resp.data.grocery;
      }
      if (!currentPrices) {
        console.error(
          `No current prices found for product ID: ${cartItem.ProductId}`
        );
        continue;
      }

      const itemIndex = CartItems.findIndex(
        (obj) => obj.ProductId === currentPrices._id
      );

      if (itemIndex !== -1) {
        let item = CartItems[itemIndex];
        const packIndex = currentPrices.PackSizes.findIndex(
          (indpack) => indpack.PackSize === item.PackSize
        );

        if (packIndex >= 0) {
          const selectedPack = currentPrices.PackSizes[packIndex];
          if (selectedPack.OutOfStack === true) {
            updatedCartItems = updatedCartItems.filter(
              (_, i) => i !== itemIndex
            );
            continue;
          }
          const updatedItem = {
            ...item,
            Price: selectedPack.SellingPrice,
            TotalAmount: selectedPack.SellingPrice * item.Qty,
            TotalPrice: selectedPack.SellingPrice * item.Qty,
            TotalMrp: selectedPack.Mrp * item.Qty,
          };
          updatedCartItems = replaceItemAtIndex(
            updatedCartItems,
            itemIndex,
            updatedItem
          );
        } else {
          console.error(
            `No matching pack size found for product ID: ${cartItem.ProductId} with pack size: ${item.PackSize}`
          );
        }
      } else {
        console.error(
          `No item found in cart for product ID: ${currentPrices._id}`
        );
      }
    }
    await dispatch(updateCart(updatedCartItems));
  };
  useEffect(() => {
    if (!isBannerAvailable || VegBannerTotal.length === 0) {
      dispatch(getSuperCatBanners());
    }
    if (!isCategoryAvailable || categoryTotal.length === 0) {
      dispatch(getCategory());
    }
    if (!isSubCategoryAvailable || subCategoryTotal.length === 0) {
      dispatch(getSubCategory());
    }

    updateCartWithCurrentPrices(CartItems);
    dispatch(getCartTotal());

    if (!isSuCatSliderAvailable || suCatSliderTotal.length === 0) {
      dispatch(getSubCatBanners());
    }
    if (!isSubCatSmallBannersAvailable || VegSubcatBannerTotal.length === 0) {
      dispatch(getSubCatSmallBanners());
    }
    if (!isProductAvailable || ProductTotal.length === 0) {
      dispatch(getProduct());
    }
    if (!isTodayFeaturerAvailable || TodayFeatureTotal.length === 0) {
      dispatch(getTodayFeaturerBanners());
    }

    if (address.length === 0) {
      dispatch(noaddressUpdate());
    }

    if (isAuth) {
      dispatch(getOrderbyClId(clientid));

      const fetchdta = async () => {
        const formData = {
          Mobile: loginData.Mobile,
        };
        const ClientDetails = await dispatch(getClientByMob(formData));
        if (ClientDetails.payload.success === false) {
        } else {
          dispatch(
            authActions.signin({
              ...ClientDetails.payload.client,
              isAuth: true,
            })
          );
        }
      };
      fetchdta();
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const url = `${Baseurl}/api/v1/massage/getmassage`;

      const respo = await axios.get(url);
      if (respo.data.success === true) {
        const respoData = respo.data.message;
        dispatch(showGroceryStatus(respoData));
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isBannerLoading) {
        dispatch(getSuperCatBanners());
      }
      if (isCategoryLoading) {
        dispatch(getCategory());
      }
      if (isSubCategoryLoading) {
        dispatch(getSubCategory());
      }

      if (isSliderLoading) {
        dispatch(getSubCatBanners());
      }
      if (isSubCatSmallBannersLoading) {
        dispatch(getSubCatSmallBanners());
      }
      if (isTodayFeaturerLoading) {
        dispatch(getTodayFeaturerBanners());
      }
      if (isProductLoading) {
        dispatch(getProduct());
      }
    }, 15000);
  }, []);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        {/* <ScrollToTop /> */}
        <Routes>
          <Route path="/sendsms/:mob/:otpWithHash" element={<SmsSend />} />
          <Route path="/" exact element={<MainLayout />}>
            <Route path="/" index element={<Home />} />

            <Route path="/:caturl" element={<Category />} />
            <Route path="/CategoryScreen" element={<CategoryScreen />} />
            <Route path="/:caturl/:subcat" element={<Subcategoey />} />
            <Route path="/CartDemo" element={<CartDemo />} />
            <Route path="/order-success" element={<OrderSuccess />} />
            <Route path="/payment-failed" element={<FailedPaymentStatus />} />
            <Route path="/payment-success" element={<SuccessPaymentStatus />} />
            <Route
              path="/payment-pending"
              element={<PendingPaaymentStatus />}
            />
            <Route
              path="/product-info/:producturl"
              element={<ProductDetails />}
            />
            <Route
              path="/subscription-product-info/:producturl"
              element={<SubscriptionProductDetails />}
            />

            <Route path="/testaccount" element={<Account />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/returnefund" element={<ReturnRefund />} />
            <Route path="/orderlistmobile" element={<OrderListMobile />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/wishlist" element={<Wishlist />} />

            <Route path="/ordertracking/:orderId" element={<OrderTracking />} />
            <Route path="/HotProductDetails" element={<HotProductDetails />} />
            <Route path="/orderScreen" element={<OrderScreen />} />
            <Route path="/addressList" element={<AddressList />} />

            <Route
              path="/category-search/:catsearch"
              element={<HomeCategorySearch />}
            />

            <Route
              path="/product-search/:prodsearch"
              element={<HomeProductSerch />}
            />

            <Route path="/home-slider/:htsurl" element={<HTBMainPage />} />

            <Route path="/home-slider" element={<HomeSliderProducts />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/account" element={<DashBoard />} />
          <Route path="/registation" element={<Registation />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
